import React from 'react'
import './CollectiblesGallery.css'
import { AiFillHeart,AiOutlineHeart } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { useState } from 'react'

import PacmanLoader from "react-spinners/PacmanLoader";
import GridLoader from "react-spinners/GridLoader"
import PulseLoader from "react-spinners/PulseLoader";

import { useEffect } from 'react';
import Web3 from 'web3';
import ERC721PayableMintAbi from '../../abis/ERC721NFTAbi.json'
import ERC721MarketAbi from '../../abis/ERC721MarketAbi.json'

import ReactPlayer from 'react-player'

var provider = process.env.REACT_APP_PROVIDER;
var web3Provider = new Web3.providers.HttpProvider(provider);
var web3 = new Web3(web3Provider);

const contractMarket = new web3.eth.Contract(ERC721MarketAbi, process.env.REACT_APP_MARKET_CONTRACT);
const contract = new web3.eth.Contract(ERC721PayableMintAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);

const CollectiblesGallery = () => {
  
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#eb1414");
  const [nfts, setNfts] = useState([]);

  function getTokenUri(uri) 
  {
    return fetch(uri)
    .then( resp => resp.json())
    .then((data)=> {
      return(data);      
    })
    .catch(function(error){
      return null;
   });
  }
  
  async function getMarketItems() {

    let arr = [];
  
    setLoading(true);

    // get Market Items
    await contract.methods.totalSupply().call()
    .then(async function(total){

      for(var i = total - 1; i >= 0; i--){

          // get token uri
          await contract.methods.tokenURI(i).call()
          .then(async function(uri){
            
            if(uri !== undefined &&
              uri !== null &&
              Object.keys(uri).length !== 0 &&
              Object.getPrototypeOf(uri) !== Object.prototype)
            {
              // get pinata uri
              await getTokenUri(uri)
              .then((res) => {
                if(res !== null){

                  let item = {"name": res.name,
                              "description": res.description,
                              "tokenIndex": i,
                              "image": res.image,
                              "animation_url": res.animation_url,
                              "owner": '0x00'
                              }

                  arr.push(item);
 
                }
              });
            }
          })
          .catch(function(error){
            // cant get owner
            setLoading(false);
          });
          
      }
      setLoading(false);
      setNfts(arr);

    })
    .catch(function(error){
      setLoading(false);
      alert("err marketItems: " + error);
    });

  }

  useEffect(() => {
    getMarketItems();
  }, [])

  const spinnerContainer: CSSProperties = {
    display: "block",
    margin: "0 auto",
    textAlign: 'center'
  };

  function getImageUrl(image){
    if(loading == true){
      setLoading(false);
    }
    return image.replace("ipfs://","https://ipfs.io/ipfs/")
  }

  function formatOwner(address){
    return address.substring(0, 6) + "..." + address.substr(address.length-5, 4);
  }

  function formatItemLink(index){
    return '/item/' + index;
  }

  function getPrice(wei){
    const etherValue = Web3.utils.fromWei(wei, 'ether');
    return etherValue;
  }

  return (
    <div className='bids section__padding'>
      <div className="bids-container">
        <div className="bids-container-text">
          <h1>Creators Gallery</h1>
          <div style={spinnerContainer}>
          <PulseLoader color={color} loading={loading} size={15} margin={8} />
          </div>
        </div>
        <div className="bids-container-card">
        <>
          {nfts.map(({tokenIndex, name, owner, description, image, animation_url}) => (
                <div className="card-column" >
                <div className="bids-card">
                  <div className="bids-card-top">
                  <Link to={formatItemLink(tokenIndex)}>
                  {!animation_url ? (<>
                    <img src={getImageUrl(image)} alt="" />
                    </>) : (<>
                      <img src={getImageUrl(image)} alt="" />
                      {/*<ReactPlayer className='react-player'
                      controls
                      width='100'
                      height='100' url={animation_url} />*/}
                    </>)}
                  </Link>
                  <Link to={formatItemLink(tokenIndex)}>
                  <p className="bids-title">{name}</p>
                  </Link>
                  </div>
                  <div className="bids-card-bottom">
                  <p>{/*Owner: {formatOwner(owner)}*/}</p>
                    <p> <AiFillHeart /></p>
                  </div>
                </div>
              </div>
          ))}
        </>

        </div>
      </div>
      <div className="load-more">
      {/*{loading ? null :<button >Load More</button>}*/}
      </div>

    </div>
  )

}

export default CollectiblesGallery
 

