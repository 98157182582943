import React from 'react'
import './info.css'
import Slider from "react-slick";
import { useRef } from "react";

import IconVR from '../../assets/i_vr.png'
import Icon360 from '../../assets/i_360.png'
import IconiReal from '../../assets/i_ireal.png'
import IconNFT from '../../assets/i_nft.png'

import iRealLogo from '../../assets/logo.png'

import { Link } from 'react-router-dom';

import { useState } from 'react'
import { useEffect } from 'react';

const Info = () => {

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#eb1414");
  const [nfts, setNfts] = useState([]);

  useEffect(() => {

  }, [])

  return (
    <div className='header section__padding'>

      <div className="header-slider" style={{ "marginTop": "0rem" }}>
        <h1 style={{ "color": "#000000", "marginTop": "0rem" }}>
          Create Augmented and Virtual Reality NFT
        </h1>
        <h2 style={{ "color": "#000000" }}>
          "Engage users, enhance educational and tourism experiences, and preserve history with iReal's interactive AR/VR NFTs."
        </h2>
      </div>

      <div class="highlights" style={{ "marginTop": "2rem" }}>
        <section>
          <div class="content">
            <header style={{ "marginTop": "0rem" }}>
              <div className="header-slider" style={{ "marginTop": "0rem" }}>
                <img style={{ "marginTop": "0rem" }} src={IconiReal} />
                <h2 style={{ "color": "#000000", "fontWeight": "300" }}>Augmented Reality</h2>
                <h3 style={{ "color": "#000000" }}>
                  iReal, an augmented reality platform, a robust XR browser, and much more. Create and publish contents in the blockchain, easily enable augmented reality on your business.
                </h3>
              </div>
            </header>
          </div>
        </section>

        <section>
          <div class="content">
            <header>
              <div className="header-slider" style={{ "marginTop": "0rem" }}>
                <img src={IconNFT} />
                <h2 style={{ "color": "#000000", "fontWeight": "300" }}>Stored in the blockchain</h2>
                <h3 style={{ "color": "#000000" }}>
                  All data is saved in a decentralized storage network (DSN),
                  associated with an NFT allowing to be sold and traded in any marketplace.

                </h3>
              </div>
            </header>
          </div>
        </section>

        <section>
          <div class="content">
            <header>
              <div className="header-slider" style={{ "marginTop": "0rem" }}>
                <img src={Icon360} />
                <h2 style={{ "color": "#000000", "fontWeight": "300" }}>360° Immersion</h2>
                <h3 style={{ "color": "#000000" }}>360 videos and photospheres in both mono and stereoscopic view, opens new horizons to the entertainment media production industry.</h3>
              </div>
            </header>
          </div>
        </section>

      </div>

      <section id="cta" class="wrapper" style={{ "marginTop": "2rem" }}>
        <div className="header-slider" style={{ "marginTop": "0rem", "width": "100%" }}>
          <h4 style={{ "color": "#000000", "marginTop": "0rem" }}>XR Magazines & Publications</h4>
          <h3 style={{ "color": "#000000" }}>
            Give your customers interactive XR/NFT products and deeper information like related offers, recipes, tips & news.
            Try&nbsp;
            <Link to='/poly/2' style={{ "color": "#eb1414", "fontWeight": "400" }}>
              Sport News Demo.
            </Link>
          </h3>
        </div>
      </section>

      <div style={{ "marginBottom": "2rem" }}></div>

      <section id="cta" class="wrapper">
        <div className="header-slider" style={{ "marginTop": "0rem", "width": "100%" }}>
          <h4 style={{ "color": "#000000", "marginTop": "0rem" }}>Rich immersive media experience.</h4>
          <h3 style={{ "color": "#000000" }}>
            transport yourself to a mixed reality experience while you're still in the real world.&nbsp;
            <Link to='/poly/1' style={{ "color": "#eb1414", "fontWeight": "400" }}>
              Portal Beach Paradise
            </Link>
            &nbsp;invites you to visit 3 beautiful beaches in 360 video.
          </h3>
        </div>
      </section>

    </div>

  )
}


export default Info
