import React from 'react';
import './item.css'
import creator from '../../assets/profile.jpg'
//import item from '../../assets/item1.png'

import { useState } from 'react'
import { useEffect } from 'react';
import Web3 from 'web3';
import ERC721PayableMintAbi from '../../abis/ERC721NFTAbi.json'
import ERC721MarketAbi from '../../abis/ERC721MarketAbi.json'

import PulseLoader from "react-spinners/PulseLoader";

import {Link, useNavigate} from 'react-router-dom';

import ReactPlayer from 'react-player'

var provider = process.env.REACT_APP_PROVIDER;
var web3Provider = new Web3.providers.HttpProvider(provider);
var web3 = new Web3(web3Provider);

const contractMarket = new web3.eth.Contract(ERC721MarketAbi, process.env.REACT_APP_MARKET_CONTRACT);
const contract = new web3.eth.Contract(ERC721PayableMintAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);


const Item = () => {

  const [nft, setNft] = useState(null);
  let [loading, setLoading] = useState(false);
  let [loadingSell, setLoadingSell] = useState(false);
  let [color, setColor] = useState("#eb1414");
  let [price, setPrice] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [tokenPriceUsd, setTokenPriceUsd] = useState('0');
  const [disabled, setDisabled] = useState(true);
  const [itemInMarket, setItemInMarket] = useState(false);
  const [marketItemId, setMarketItemId] = useState(-1);
  const [gasPrice, setGasPrice] = useState(0);

  async function isItemInMarket(tokenId) {
    
    setLoading(true);

    // get Market Items
    await contractMarket.methods.getUnsoldItems().call()
    .then(async function(unsoldItems){

      setLoading(false);

      for(var i = 0; i < unsoldItems.length; i++){

        if(unsoldItems[i].tokenId === tokenId)
        {
          setItemInMarket(true);
          setMarketItemId(unsoldItems[i].itemId);
          break;
        }
          
      }

    })
    .catch(function(error){
      setLoading(false);
      //alert("err Item: " + error);
    });

  }

  function getTokenUri(uri) 
  {
    return fetch(uri)
    .then( resp => resp.json())
    .then((data)=> {
      return(data);      
    })
    .catch(function(error){
      return null;
    });
  }

  async function getNft() {

    var tokenIndex = window.location.pathname.split("/").pop();
    isItemInMarket(tokenIndex);

    setLoading(true);

    // get owner of [i]
    await contract.methods.ownerOf(tokenIndex).call()
    .then(async function(owner){
      
        // get token uri
      await contract.methods.tokenURI(tokenIndex).call()
      .then(async function(uri){
        if(uri !== undefined &&
          uri !== null &&
          Object.keys(uri).length !== 0 &&
          Object.getPrototypeOf(uri) !== Object.prototype)
        {
          // get pinata uri
          await getTokenUri(uri)
          .then((res) => {
            if(res !== null){
              res.owner = owner;
              res.index = tokenIndex;
              setNft(res);
            }

            setLoading(false);
          });
        }
      })
      .catch(function(error){
        // cant get owner
        setLoading(false);
      });
      
    });

  }

  let navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  async function sellNft() {

    if(price === 0){
      return;
    }

    //var injectedWeb3 = new Web3(window.web3.currentProvider);
    var injectedWeb3 = new Web3(window.connection);
    var contractMarketInjected = new injectedWeb3.eth.Contract(ERC721MarketAbi, process.env.REACT_APP_MARKET_CONTRACT);
 
    var sender = window.account;

    var convertedPrice =  "0x" + Web3.utils.toBN(Web3.utils.toWei(price, "ether")).toString(16);
  
    setLoadingSell(true);

    var result = await contractMarketInjected.methods.addItemToMarket(process.env.REACT_APP_NFT_CONTRACT_MATIC, nft.index, convertedPrice)
      .send({
        from: sender,
        gasPrice: gasPrice,
        gas: 3000000
      })
      .on('transactionHash', function(hash){
        // prior to confirm
      })
      .on('confirmation', function(confirmationNumber, receipt){
        goHome();
      })
      .on('error', function(error, receipt) {
        setLoadingSell(false);
        
        var message = JSON.stringify(error);
        if(message.toLowerCase().includes('user denied'))
        {
          alert('Transaction canceled.');  
        }

      });

  }

  function getTokenPrice() 
  {
    return fetch('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd')
    .then( resp => resp.json())
    .then((data)=> {
      var price = data['matic-network'].usd;
      setTokenPrice(price);
    })
    .catch(function(error){
   });
  }

  async function getGasPrice()
  {

    await web3.eth.getGasPrice()
    .then((data)=> {
      setGasPrice(data);
    })
    .catch(function(error){
    });

  }

  useEffect(() => {
    getGasPrice();
    getTokenPrice();
    setPrice(0);
    getNft();
    window.scrollTo(0, 0);
  }, [])

  const spinnerContainer: CSSProperties = {
    display: "block",
    margin: "0 auto",
    textAlign: 'center'
  };

  function getImageUrl(image){
    return image.replace("ipfs://","https://ipfs.io/ipfs/")
  }

  function formatOwner(address){
    return address.substring(0, 6) + "..." + address.substr(address.length-5, 4);
  }

  const onPriceChange = (event) => {

    var val = event.target.value; 
    setPrice(val);
    setTokenPriceUsd(getPriceUsd(val));

    if(price === 0){
      setDisabled(true);
    }else{
      setDisabled(false);
    }

  }

  function getPriceUsd(ether){
    var usd = ether * tokenPrice;
    return Math.round(usd * 100) / 100;
  }

  function isOwner(account){
    if(window.account === account){
      return true;
    }
    return false;
  }

  function formatItemLink(){
    return '/marketitem/' + marketItemId;
  }


  return( 
      <div className='item section__padding'>

        {nft ? ( 
        <>
        <div className="item-image">   
          {!nft.animation_url ? ( <>
            <img src={getImageUrl(nft.image)} alt="item" />
          </>) : (<>
            <ReactPlayer className='react-player2'
                      controls
                      url={nft.animation_url} />
          </>)}  
        </div>
          <div className="item-content">
            <div className="item-content-title">
              <h1>{nft.name}</h1>
              {/*<p>From <span>4.5 ETH</span></p>*/}
            </div>
            <div className="item-content-creator">             
              <div>
                <img src={creator} alt="creator" />
                <p>
                {itemInMarket ? ( 
                  <>          
                    Item in market
                  </>) : (<>
                    {isOwner(nft.owner) ? (
                    <>
                      You own this item
                    </>) : (<>
                      Owner
                    </>)}  
                    <br></br>{formatOwner(nft.owner)}
                  </>)}
                </p>
              </div>
            </div>
            <div className="item-content-detail">
              <p>{nft.description}</p>
            </div>
            <div className="item-content-buy">

            {loadingSell ? (<>
                    <div style={spinnerContainer}>
                      <PulseLoader color={color} loading={loadingSell} size={15} margin={8} />
                    </div>
                  </>) : (<>
              
              {itemInMarket ? (<>
                  <Link to={formatItemLink()}>
                    <button className="primary-btn" id="btnSell">View in Market</button>
                  </Link>
                </>) : (<>
                  {isOwner(nft.owner) ? (
                    <>
                  <label>Price: </label>
                  <input type="number" onChange={onPriceChange} value={price} min="0" step=".01" />
                  <label> {process.env.REACT_APP_SYMBOL} </label>
                  <label> ($ {tokenPriceUsd}) </label>
                  <button className="primary-btn" id="btnSell" onClick={sellNft}>Sell</button>
                  </>) : (<>
                      
                    </>)}  
                </>)}
              </>)}
            </div>
          </div>
          </>
          ):(
          <>
          <div style={spinnerContainer}>
            <PulseLoader color={color} loading={loading} size={15} margin={8} />
          </div>
          </>)}
      </div>

  )
};

export default Item;
