import React from 'react'
import './about.css'

import IconVR from '../../assets/i_vr.png'
import Icon360 from '../../assets/i_360.png'
import IconiReal from '../../assets/i_ireal.png'

import { Link } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';

import { CSSProperties } from "react";

const About = () => {

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#eb1414");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#000000',
      opacity: '90%',
      width: '200px',
      height: '100px'
  },
    overlay: {
      background: "#000000",
      opacity: '90%'
    }
  };


  return (
    <div className='bids section__padding'>
      <div className="bids-container">
        <div className="bids-container-text">
          <h1>Bring your contents to life</h1>

        </div>
        <div className="bids-container-card" style={{"display": "block", "alignItems": "center", "justifyContent": "center"}}>

              <div className="card-column">
                <div className="bids-card">
                  <div className="bids-card-top">
                      <img src={IconiReal}alt="" />
                      <p className="bids-title">Augmented Reality</p>
                  </div>
                  <div className="bids-card-bottom" style={{"marginTop": "10px"}}>
                  <p>iReal, an augmented reality platform, a robust AR browser, and much more. Create and publish contents in the cloud and easily enable augmented reality on your business.</p>
              
                  </div>
                </div>
              </div>

              <div className="card-column">
                <div className="bids-card">
                  <div className="bids-card-top">
                      <img src={Icon360} alt="" />
                      <p className="bids-title">360° Immersion</p>
                  </div>
                  <div className="bids-card-bottom">
                  <p style={{"marginTop": "10px"}}>Support 360 video and photospheres in both mono and stereoscopic view, opens new horizons to the entertainment media production industry.</p>
                  </div>
                </div>
              </div>


        <div className="card-column" >
          <div className="bids-card">
            <div className="bids-card-top">
              <img src={IconVR} alt="" />
              <p className="bids-title">3D Stereocopy</p>
            </div>
            <div className="bids-card-bottom" style={{"marginTop": "10px"}}>
            <p>Carboard + AR + VR.
Experience virtual reality in a simple, fun and affordable way.</p>

            </div>
          </div>
        </div>

        </div>

      </div>

    </div>
  )

};

export default About;
