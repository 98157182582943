import React from 'react';
import './profile.css'
import profile_banner from '../../assets/profile_banner.jpg'
import profile_pic from '../../assets/profile.jpg'

import { MyItems } from '../../components';

var profileAccount;

const Profile = () => {

  function formatOwner(){

    profileAccount = window.location.pathname.split("/").pop();

    if(profileAccount != null)
    {
      var address = profileAccount;
      return address.substring(0, 6) + "..." + address.substr(address.length-5, 4);
    }
    else{
      return "";
    }
  }
   
  return (
    <div className='profile section__padding'>
      <div className="profile-top">
        <div className="profile-banner">
          <img src={profile_banner} alt="banner" />
        </div>
        <div className="profile-pic">
            <img src={profile_pic} alt="profile" />
            <h3 style={{"color": "#000000"}}>{formatOwner()}</h3>
        </div>
      </div>
      <div className="profile-bottom">
        <MyItems profileAccount={profileAccount} />
      </div>
    </div>
  );
};

export default Profile;
