import React from 'react';
import './item.css'
import creator from '../../assets/profile.jpg'
import shareIcon from '../../assets/share.png'
import cloneIcon from '../../assets/clone.png'
import transferIcon from '../../assets/send.png'
import burnIcon from '../../assets/burn.png'

import { useState } from 'react'
import { useEffect } from 'react';
import Web3 from 'web3';
import iRealNFTPolyAbi from '../../abis/iRealNFTPolyAbi.json'
import PulseLoader from "react-spinners/PulseLoader";

import { useNavigate } from "react-router-dom";

import ReactPlayer from 'react-player'
import QRCode from "react-qr-code";
import { Link } from 'react-router-dom';

var provider = process.env.REACT_APP_PROVIDER;
var web3Provider = new Web3.providers.HttpProvider(provider);
var web3 = new Web3(web3Provider);

const contract = new web3.eth.Contract(iRealNFTPolyAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);

const style = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 30px',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
};

const Item = () => {


  const [nft, setNft] = useState(null);
  let [loading, setLoading] = useState(false);
  let [loadingBuy, setLoadingBuy] = useState(false);
  let [color, setColor] = useState("#eb1414");
  const [tokenPrice, setTokenPrice] = useState(0);
  const [gasPrice, setGasPrice] = useState(0);
  const [tokenPriceUsd, setTokenPriceUsd] = useState('0');
  const [clonePrice, setClonePrice] = useState(0);


  function getTokenUri(uri) 
  {
    return fetch(uri)
    .then( resp => resp.json())
    .then((data)=> {
      return(data);      
    })
    .catch(function(error){
      //alert("err pinataUri: " + error);
      return null;
    });
  }

  async function getNft() {

    var itemId = window.location.pathname.split("/").pop();

    setLoading(true);

       // get NFT Items
       await contract.methods.totalSupply().call()
       .then(async function(total){
        
         for(var i = 0; i < total; i++){
   
             // get token by index
             await contract.methods.tokenByIndex(i).call()
             .then(async function(tokenId){
              
              if(tokenId == itemId)
              {
                
                await contract.methods.ownerOf(tokenId).call()
                .then(async function(owner){ 
                  
                  await contract.methods.cloned(tokenId).call()
                  .then(async function(cloned){ 

                    // get token uri
                    await contract.methods.tokenURI(tokenId).call()
                    .then(async function(uri){
                      
                      if(uri !== undefined &&
                        uri !== null &&
                        Object.keys(uri).length !== 0 &&
                        Object.getPrototypeOf(uri) !== Object.prototype)
                      {
                        // get uri
                        await getTokenUri(uri)
                        .then((res) => {
                          if(res !== null){
                                  
                            let item = {"name": res.name,
                                        "description": res.description,
                                        "tokenId": tokenId,
                                        "image": res.image,
                                        "animation_url": res.animation_url,
                                        "chain": 'poly',
                                        "owner": owner,
                                        "attributes": res.attributes,
                                        "cloned": cloned,
                                        "uri": uri
                                        }
                            setNft(item);
                            setLoading(false);
                          
                          }
                        });
                      }
                    })
                    .catch(function(error){
                      // cant get uri
                      setLoading(false);
                    });

                  })
                  .catch(function(error){
                    // cant get cloned
                    alert(error);
                    setLoading(false);
                  });

                })
                .catch(function(error){
                  // cant get owner
                  setLoading(false);
                });

              }
                
             })
             .catch(function(error){
               // cant get token by index
               setLoading(false);
             });
             
         }

         setLoading(false);
   
       })
       .catch(function(error){
         setLoading(false);
         alert("err marketItems: " + error);
       });
    
  }

  let navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  function getTokenPrice() 
  {
    return fetch('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd')
    .then( resp => resp.json())
    .then((data)=> {
      var price = data['matic-network'].usd;
      setTokenPrice(price);
    })
    .catch(function(error){
   });
  }

  async function getGasPrice()
  {

    await web3.eth.getGasPrice()
    .then((data)=> {
      setGasPrice(data);
    })
    .catch(function(error){
    });

  }

  useEffect(() => {
    getGasPrice();
    getClonePrice();
    getTokenPrice();
    getNft();
    window.scrollTo(0, 0);
    //checkForRelatedApps();
  }, [])

  function checkForRelatedApps() {

    alert(1);
    navigator.getInstalledRelatedApps().then(relatedApps => {
      alert(2);
      alert(relatedApps.length);
      for (let app of relatedApps) {
        alert(app.platform);
        alert(app.url);
        alert(app.id);
      }
    })
    .catch(function(error){
      alert(error);
    });

  }

  function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
  }

  const spinnerContainer: CSSProperties = {
    display: "block",
    margin: "0 auto",
    textAlign: 'center'
  };

  function formatOwner(address){
    return address.substring(0, 6) + "..." + address.substr(address.length-5, 4);
  }

  function getPrice(wei){
    const etherValue = Web3.utils.fromWei(wei, 'ether');
    return etherValue;
  }

  function getPriceUsd(wei){
    const etherValue = Web3.utils.fromWei(wei, 'ether');
    var usd = etherValue * tokenPrice;
    return Math.round(usd * 100) / 100;
  }

  function formatDescription(description){

    const desc = description.split('[');
    return desc[0];
  }

  const handleShare = () => {

    if (navigator.share) {
      navigator
        .share({
          title: "Go iReal!",
          text: nft.name + ". Change the way you experience reality.",
          url: window.location.pathname,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong", error);
        });
    }
  };

  async function handleClone () {
   
    if(window.ethereum.networkVersion !== process.env.REACT_APP_CHAIN_ID)
    {
      alert('To continue please switch to ' + getNetworkName(process.env.REACT_APP_CHAIN_ID) + ' network');

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(process.env.REACT_APP_CHAIN_ID) }],
        });
      
      return;
    }
    
    setLoading(true); 
 
    var injectedWeb3 = new Web3(window.connection);
    var contractInjected = new injectedWeb3.eth.Contract(iRealNFTPolyAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);
    
    var mintTo = window.account;

    var result = await contractInjected.methods.cloneMint(window.account, nft.tokenId)
    .send({
      from: mintTo,
      value: clonePrice,
      gasPrice: gasPrice,
      gas: 3000000
    })
    .on('transactionHash', function(hash){
      // prior to confirm
    })
    .on('confirmation', function(confirmationNumber, receipt){
      setLoading(false);
      goHome();
    })
    .on('error', function(error, receipt) {
      setLoading(false);
      var message = JSON.stringify(error);
      if(message.toLowerCase().includes('user denied'))
      {
        alert('Mint canceled.');  
      }
    });
  
  };

  async function handleTransfer () {
    
    if(window.ethereum.networkVersion !== process.env.REACT_APP_CHAIN_ID)
    {
      alert('To continue please switch to ' + getNetworkName(process.env.REACT_APP_CHAIN_ID) + ' network');

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(process.env.REACT_APP_CHAIN_ID) }],
        });
      
      return;
    }
 
    var transferToAddress = prompt('Enter the destination address')

    if(transferToAddress){

      var valid = web3.utils.isAddress(transferToAddress); 

      if(!valid){
        alert('The destination address is not valid');
      }
      else{
        if (window.confirm('Transfer NFT item: You will asked to approve this transfer from your wallet.')) {
          // Transfer
          transfer(transferToAddress);
        } else {
        }
      }

    }
     
  }

  async function handleBurn () {
  
    if(window.ethereum.networkVersion !== process.env.REACT_APP_CHAIN_ID)
    {
      alert('To continue please switch to ' + getNetworkName(process.env.REACT_APP_CHAIN_ID) + ' network');

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(process.env.REACT_APP_CHAIN_ID) }],
        });
      
      return;
    }
 
    if (window.confirm('Burn a non-fungible token means destroy/eliminate your NFT from the blockchain. Are you sure you want to continue?')) {
      // Transfer
      burn();
    } else {
    }

  }

  async function burn () {
    
    setLoading(true); 
 
    var injectedWeb3 = new Web3(window.connection);
    var contractInjected = new injectedWeb3.eth.Contract(iRealNFTPolyAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);
    
    var from = window.account;

    var result = await contractInjected.methods.burn(nft.tokenId)
    .send({
      from: from,
      gasPrice: gasPrice,
      gas: 3000000
    })
    .on('transactionHash', function(hash){
      // prior to confirm
    })
    .on('confirmation', function(confirmationNumber, receipt){
      setLoading(false);
      goHome();
    })
    .on('error', function(error, receipt) {
      setLoading(false);
      var message = JSON.stringify(error);
      if(message.toLowerCase().includes('user denied'))
      {
        alert('Burn canceled.');  
      }
    });
  
  };

  async function transfer (to) {
    
    setLoading(true); 
 
    var injectedWeb3 = new Web3(window.connection);
    var contractInjected = new injectedWeb3.eth.Contract(iRealNFTPolyAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);
    
    var from = window.account;

    var result = await contractInjected.methods.safeTransferFrom(from, to, nft.tokenId)
    .send({
      from: from,
      value: clonePrice,
      gasPrice: gasPrice,
      gas: 3000000
    })
    .on('transactionHash', function(hash){
      // prior to confirm
    })
    .on('confirmation', function(confirmationNumber, receipt){
      setLoading(false);
      goHome();
    })
    .on('error', function(error, receipt) {
      setLoading(false);
      var message = JSON.stringify(error);
      if(message.toLowerCase().includes('user denied'))
      {
        alert('Mint canceled.');  
      }
    });
  
  };

  async function getClonePrice(){

    await contract.methods.clonePrice().call()
    .then(function(value){
      setClonePrice(value);
    })
    .catch(function(error){
    });

  }

  function getNetworkName(chainId)
  {

    var name = 'other network';

    switch(chainId)
    {
      case "1":
        name = 'Ethereum';
        break;

      case "56":
        name =  'BSC';
        break;

      case "137":
        name =  'Polygon';
        break;

      case "80001":
        name =  'Mumbai';
        break;

      default:
        name = 'other network';
    }

    return name;

  }

  var goRealTimeout;
  
  function handleGoReal(){

    goRealTimeout = setTimeout(function () { 

      var os = getOS();
      if(os === 'Android' ||
        os === 'Windows'){
        window.location = "https://play.google.com/store/apps/details?id=info.iReal"; 
      }
    }, 200);

    window.location = "ireal://ireal.ar/" + nft.chain + "/" + + nft.tokenId;

  }

  window.addEventListener("focus", function(event) 
  { 
    if(goRealTimeout != null){
      clearTimeout(goRealTimeout);
    }
  }, false);

  window.addEventListener("blur", function(event) 
  { 
    if(goRealTimeout != null){
      clearTimeout(goRealTimeout);
    }
  }, false);


  function getAttributes() {

    const list = [];

    for(var key in nft.attributes){

      switch(key){

        case 'XR':  
        
          list.push(<button class='xr-attribute'>{nft.attributes[key]}</button>);
          break;

        case 'Feature':  
          if(nft.attributes[key] == 'ImageTracking'){
            list.push(<button class='xr-attribute'>Image Tracking</button>);
          }
          if(nft.attributes[key] == 'GroundPlane'){
            list.push(<button class='xr-attribute'>Plane Tracking</button>);
          }
          if(nft.attributes[key] == 'ImmersiveVR'){
            list.push(<button class='xr-attribute'>Immersive VR</button>);
          }

          break;

        case 'Printable':  
            
            list.push(
              <a href={nft.attributes[key]} target="_blank" title={"Download '" + nft.name + "' printable"}>
                <button class='xr-attribute' style={{"cursor": "pointer", "background": "var(--primary-btn)", "color": "#fff"}}>Printable</button>
              </a>
              );
          break;
        
        case 'Category':  

            if(nft.attributes[key]){
              list.push(<button class='xr-attribute'>{nft.attributes[key]}</button>);    
            }

        case 'Locked':  

            if(nft.attributes[key] == 'True'){
              list.push(<button class='xr-attribute'>Locked</button>);    
            }

        default:  
        
          //list.push(<button class='xr-attribute'>{nft.attributes[key]}</button>);
        break;

      }
      
    }

    return(list);

  }

  return( 
      <div className='item section__padding'>
        {nft ? ( 
        <>
        <div className="item-image" style={{"display": "block", "alignSelf": "center"}}>
          {!nft.animation_url ? ( <>
            <img src={nft.image} alt="item" />
          </>) : (<>
            <ReactPlayer className='react-player2'
                      controls
                      url={nft.animation_url} />
          </>)}         
          <div style={{"justify-content": "center", "display": "flex", "alignSelf": "center"}}>
            {getAttributes()}
          </div>
        </div>
          <div className="item-content">
            <div className="item-content-title">
              <h1 style={{"width": "100%"}}>{nft.name}
              </h1>
            </div>
            <div className="item-content-creator"> 
              <div>
              <div style={{ background: 'white'}}>
              <QRCode value={"https://ireal.ar/" + nft.chain + "/" + nft.tokenId} style={{margin: "8px"}} size="100" />  
              </div>
                <img src={creator} alt="creator" style={{marginLeft: "4px"}} />
                <p>
                  {(window.account === nft.owner)?(
                  <>
                    You own this item
                  </>):(
                  <>
                    Owner
                  </>)}
                  <br></br>{formatOwner(nft.owner)}
                </p>
                
              </div>
            </div>
            <div className="item-content-detail">
              <p>{formatDescription(nft.description)}</p>
            </div>
            <div className="item-content-buy">

            <div style={{"display": "flex", "align-items": "center", "justify-content": "center"}}>

            {loading ? (<>
              <div style={spinnerContainer}>
                <PulseLoader color={color} loading={loading} size={15} margin={8} />
              </div>
              
            </>) : (<>
                <button onClick={handleGoReal} className="primary-btn">Open</button>

                {(window.account === nft.owner)?(<>
                  <Link to={"/profile/" + nft.owner}>
                    <button className="primary-btn">My NFTs</button>
                  </Link>
                  </>) : (<>
                  <Link to={"/profile/" + nft.owner}>
                    <button className="primary-btn">Owner NFTs</button>
                  </Link>
                  </>)}
                  
                    <img src={shareIcon} onClick={handleShare} style={{"marginLeft": "0rem", "cursor": "pointer", "width": "20px", "height": "20px"}} title="Share" />
                    {(window.account === nft.owner)?(<>
                      {!nft.cloned ? (<>
                        <img src={cloneIcon} onClick={handleClone} style={{"marginLeft": "2rem", "cursor": "pointer", "width": "22px", "height": "22px"}} title="Mint a copy" />
                        </>) : (<>
                        </>)}
                        <img src={transferIcon} onClick={handleTransfer} style={{"marginLeft": "2rem", "cursor": "pointer", "width": "22px", "height": "20px"}} title="Transfer" />
                        <img src={burnIcon} onClick={handleBurn} style={{"marginLeft": "2rem", "cursor": "pointer", "width": "18px", "height": "20px"}} title="Burn" />
                    </>) : (<>
                    </>)}
            </>)}

                </div>           

            </div>

            <div className="item-content-detail">
              <Link to={"/downloads"}>
                <p style={{"color": "red"}}>
                  <small>
                    Download iReal
                  </small>
                  </p>
              </Link>
            </div>

          </div>
          </>
          ):(
          <>
          <div style={spinnerContainer}>
            <PulseLoader color={color} loading={loading} size={15} margin={8} />
          </div>
          </>)} 
      </div>

  )
};

export default Item;

