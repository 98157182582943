import React from 'react'
import './Market.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useRef } from "react";

import iRealLogo from '../../assets/logo.png'

import { Link  } from 'react-router-dom';

import { useState } from 'react'

import PulseLoader from "react-spinners/PulseLoader";

import { useEffect } from 'react';
import Web3 from 'web3';
import iRealNFTPolyAbi from '../../abis/iRealNFTPolyAbi.json'
import { RiAlertLine } from 'react-icons/ri';

var provider = process.env.REACT_APP_PROVIDER;
var web3Provider = new Web3.providers.HttpProvider(provider);
var web3 = new Web3(web3Provider);

const contract = new web3.eth.Contract(iRealNFTPolyAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);

const Market = () => {

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#eb1414");
  const [nfts, setNfts] = useState([]);

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        initialSlide: 0,
        slidesToScroll: 1
      }
    }
  ]
};






  function getTokenUri(uri) 
  {   
    return fetch(uri)
    .then( resp => resp.json())
    .then((data)=> {
      return(data);      
    })
    .catch(function(error){alert(error);
      return null;
   });
  }

  async function getItems() {

    let arr = [];
    
    setLoading(true);

    // get NFT Items
    await contract.methods.totalSupply().call()
    .then(async function(total){
           
      var index = 0;
      for(var i = total - 1; i >= 0; i--){

          // get token by index
          await contract.methods.tokenByIndex(i).call()
          .then(async function(tokenID){

            // get cloned
            await contract.methods.cloned(tokenID).call()
            .then(async function(cloned){

              // get token uri
              await contract.methods.tokenURI(tokenID).call()
              .then(async function(uri){
                
                if(uri !== undefined &&
                  uri !== null &&
                  Object.keys(uri).length !== 0 &&
                  Object.getPrototypeOf(uri) !== Object.prototype)
                {
                  // get token uri
                  await getTokenUri(uri)
                  .then((res) => {
                    if(res !== null){
                            
                      if(!cloned){
                        
                        index += 1;

                        let item = {"name": res.name,
                                    "description": res.description,
                                    "tokenId": tokenID,
                                    "index": total - index,
                                    "image": res.image,
                                    "animation_url": res.animation_url,
                                    "chain": 'poly',
                                    "owner": '0x00',
                                    "cloned": cloned
                                    }

                        
                        arr.push(item);
                      }
    
                    }
                  });
                }
              })
              .catch(function(error){
                // cant get owner
                setLoading(false);
              });
              
            })
            .catch(function(error){
              // cant get cloned
              setLoading(false);
            });

          })
          .catch(function(error){
            // cant get token by index
            setLoading(false);
          });
          
      }
      setLoading(false);

      arr.sort(function(a, b) {
        return parseFloat(b.tokenId) - parseFloat(a.tokenId);
      });

      setNfts(arr);

      slider.current.slickPrev();

    })
    .catch(function(error){
      setLoading(false);
      alert("err marketItems: " + error);
    });

  }

  useEffect(() => {
    getItems();
  }, [])

  const spinnerContainer: CSSProperties = {
    display: "block",
    margin: "0 auto",
    textAlign: 'center'
  };

  function formatOwner(address){
    return address.substring(0, 6) + "..." + address.substr(address.length-5, 4);
  }

  function formatDescription(description){

    const desc = description.split('[');
    return desc[0].substring(0, 65) + "...";
  }

  function formatItemLink(chain, itemId){
    return '/' + chain + '/' + itemId;
  }

  function getPrice(wei){
    const etherValue = Web3.utils.fromWei(wei, 'ether');
    return etherValue;
  }

  const slider = useRef(null);

  return (
    <div className='header section__padding'>
      <div className="header-content" style={{"width": "100%"}}>
        <div style={{"width": "100%"}}>
          <h1 style={{"width": "100%"}}>iReal. Bring your contents to life.</h1>
          <img src={iRealLogo} style={{"width": "8%", "marginRight": "3rem"}} alt="" />
        </div>
      </div>
      <div className="header-slider">
        <h1 style={{"color": "#000000"}}>Trending</h1>
       <Slider ref={slider} {...settings} className='slider'>

       {nfts.map(({tokenId, index, name, description, image, chain}) => (  

             <div className='slider-card'>
             <p className='slider-card-number' style={{"zIndex": "100"}}>{index}</p>
             <div className="slider-img">
               <Link to={formatItemLink(chain, tokenId)}>
                 <img src={image} title={formatDescription(description)} />
               </Link>
             </div>
             <Link to={formatItemLink(chain, tokenId)}>
               <p className='slider-card-name' style={{marginLeft: 5, marginRight: 5, marginTop: 10}}>
               <small>
                {name}
                </small>
                </p>      
             </Link>
             
             <p style={{marginLeft: 5, marginRight: 5}}>
              {/*
                <small><small><small>
                {formatDescription(description)}
                </small></small></small>
              */}
             </p>
               
             <p className='slider-card-price'>
               <small>
                {/* {getPrice(price)}<span> {process.env.REACT_APP_SYMBOL}</span> */}
               </small>
               </p>
            
           </div>

         ))}
        </Slider>
      </div>

      <div style={spinnerContainer}>
          <PulseLoader color={color} loading={loading} size={15} margin={8} />
      </div>

    </div>
  )
}


export default Market
