import React from 'react'
import './MyItems.css'
import { AiFillHeart,AiOutlineHeart } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { useState } from 'react'

import maticLogo from "../../assets/matic.svg";
import ethLogo from "../../assets/eth.svg";

import PulseLoader from "react-spinners/PulseLoader";

import { useEffect } from 'react';
import Web3 from 'web3';

import iRealNFTPolyAbi from '../../abis/iRealNFTPolyAbi.json'

var provider = process.env.REACT_APP_PROVIDER;
var web3Provider = new Web3.providers.HttpProvider(provider);
var web3 = new Web3(web3Provider);

const contract = new web3.eth.Contract(iRealNFTPolyAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);

const MyItems = ({profileAccount}) => {
  
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#eb1414");
  const [nfts, setNfts] = useState([]);

  function getTokenUri(uri) 
  {
    return fetch(uri)
    .then( resp => resp.json())
    .then((data)=> {
      return(data);      
    })
    .catch(function(error){
      //alert("err pinataUri: " + error);
      return null;
   });
  }

  async function getNfts() {

    let arr = [];

    setLoading(true);

    // get balanceOf (address)
    await contract.methods.balanceOf(profileAccount).call()
    .then(async function(balance){

      for(var i = balance - 1; i >= 0; i--){

        // get owner of [i]
        await contract.methods.tokenOfOwnerByIndex(profileAccount, i).call()
        .then(async function(index){
          
            // get token uri
          await contract.methods.tokenURI(index).call()
          .then(async function(uri){
            if(uri !== undefined &&
              uri !== null &&
              Object.keys(uri).length !== 0 &&
              Object.getPrototypeOf(uri) !== Object.prototype)
            {
              // get pinata uri
              await getTokenUri(uri)
              .then((res) => {
                if(res !== null){
                  res.owner = profileAccount;
                  res.index = index;
                  res.chain = 'poly'
                  arr.push(res);
                }
              });
            }
          })
          .catch(function(error){
            // cant get owner
            setLoading(false);
          });
          
        });

        
      }

      setLoading(false);
      setNfts(arr);

    })
   .catch(function(error){
      //alert("err tokenUri: " + error);
      setLoading(false);
   });
    
  }

  useEffect(() => {
    getNfts();
    window.scrollTo(0, 0);
  }, [])

  const spinnerContainer: CSSProperties = {
    display: "block",
    margin: "0 auto",
    textAlign: 'center'
  };

  function formatOwner(address){
    return address.substring(0, 6) + "..." + address.substr(address.length-5, 4);
  }

  function formatItemLink(index){
    return '/item/' + index;
  }

  function formatDescription(description){

    const desc = description.split('[');
    return desc[0].substring(0, 65) + "...";
  }

  return (
    <div className='bids section__padding'>
      <div className="bids-container">
        <div className="bids-container-text">
          <h1>
          {profileAccount === window.account ? ( 
            <>
            My&nbsp;
            </>) : (<>
              </>)} 
            NFTs</h1>
          <div style={spinnerContainer}>
          <PulseLoader color={color} loading={loading} size={15} margin={8} />
          </div>
        </div>
        <div className="bids-container-card">
        <>
        {nfts.map(({index, name, owner, description, image, chain }) => (
                <div className="card-column" >
                <div className="bids-card">
                  <div className="bids-card-top">
                  <Link to={"/poly/" + index}>
                    <img src={image} alt="" />
                  </Link>
                  <Link to={"/poly/" + index}>
                  <p className="bids-title">
                    <small>
                    {name}
                    </small>
                    </p>
                  </Link>
                  </div>
                  <div className="bids-card-bottom">
                  <p>
                  <small>
                    {formatDescription(description)}
                    </small>
                    </p>
                  
                  </div>
                  <div className="bids-card-bottom">
                  <p>
                  {chain = 'poly' ? ( 
                    <>
                      <img src={maticLogo} style={{width: "20px", "marginTop": "10px"}} title="Polygon Network" alt="Polygon Network" />
                    </>) : (<>
                      <img src={ethLogo} style={{width: "20px", "marginTop": "10px"}} title="Ethereum Network" alt="Ethereum Network" />
                    </>)} 
                    </p>
                  <p style={{width: "20px", "marginTop": "12px"}}> <AiFillHeart /></p>
                  </div>
                </div>
              </div>
          ))}
        </>

        </div>
      </div>
      <div className="load-more">
      {loading ? null : <button >Load More</button>}
      </div>

    </div>
  )

}

export default MyItems
 

