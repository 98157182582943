import React from 'react'
import './create.css'
import Image from '../../assets/Image.png'

import { useState } from 'react';
import { useEffect } from 'react';

import mergeImages from 'merge-images';

import Web3 from 'web3';
import ERC721PayableMintAbi from '../../abis/ERC721NFTAbi.json'
import ERC721MarketAbi from '../../abis/ERC721MarketAbi.json'

import { CSSProperties } from "react";

import PulseLoader from "react-spinners/PulseLoader";

import { useNavigate } from "react-router-dom";

import Arweave from "arweave";

 
var provider = process.env.REACT_APP_PROVIDER;
var web3Provider = new Web3.providers.HttpProvider(provider);
var web3 = new Web3(web3Provider);

const contractMarket = new web3.eth.Contract(ERC721MarketAbi, process.env.REACT_APP_MARKET_CONTRACT);
const contract = new web3.eth.Contract(ERC721PayableMintAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);

const arweave = Arweave.init({
  host: "arweave.net",
  port: 443,
  protocol: "https",
  timeout: 20000, // Network request timeouts in milliseconds
  logging: false, // Disable network request logging
});

const Create = () => {

  const background = "/images/arcadeboys/background/" + (Math.floor(Math.random() * 15) + 1).toString() + ".png";
  const bodystroke = "/images/arcadeboys/bodystroke/" + (Math.floor(Math.random() * 3) + 1).toString() + ".png";
  const body = "/images/arcadeboys/body/" + (Math.floor(Math.random() * 8) + 1).toString() + ".png";
  const eyes = "/images/arcadeboys/eyes/" + (Math.floor(Math.random() * 6) + 1).toString() + ".png";
  const antenna = "/images/arcadeboys/antenna/" + (Math.floor(Math.random() * 3) + 1).toString() + ".png";
  const shirt = "/images/arcadeboys/shirt/" + (Math.floor(Math.random() * 9) + 1).toString() + ".png";
  const accesorie = "/images/arcadeboys/accesorie/" + (Math.floor(Math.random() * 12) + 1).toString() + ".png";
  
  function createBoy(){
    mergeImages([background, bodystroke, body, eyes, antenna, shirt, accesorie])
    .then(function(b64) {
      document.querySelector('#image').src = b64;
      setArcadeboyb64(b64);
    });
  }
  
  async function uploadToArweave(){

    // The data we're uploading
    var arr = arcadeboyb64.split(',')
    var mime = arr[0].match(/:(.*?);/)[1]
    var str64 = arr[1];
    const imageBuffer = Buffer.from(str64, "base64");

    // Create and submit transaction
    const key = JSON.parse(process.env.REACT_APP_ARWEAVE);
    const transaction = await arweave.createTransaction(
      {
        data: imageBuffer
      },
      key
    );

    await arweave.transactions.sign(transaction, key);
    await arweave.transactions.post(transaction);
  
    // Transaction ID gets updated after arweave.transactions.post, which is a bit unintuitive
    //alert("transaction ID " + transaction.id);
  
    // Read data back
    //const transactionData = await arweave.transactions.getData(transaction.id);
    //alert("transaction data " + Buffer.from(transactionData, "base64").toString());

    var current = parseInt(minted) + parseInt(1);
    var name = 'Arcade Boy #' + current;
    var description = 'Arcade Boys is a collection of dedicated NFTs, they will unlock access to a wide range of features as we build our community and includes exclusive benefits from upcoming music releases. Membership to the collective and all of the benefits come from holding an Arcade Boy NFT.';
    var image = "https://arweave.net/" + transaction.id;

    // json data
    var json = new Object();
    json.description = description;
    json.image = image;
    json.name = name;

    var jsonData = JSON.stringify(json);

    const transaction2 = await arweave.createTransaction(
      {
        data: jsonData
      },
      key
    );

    await arweave.transactions.sign(transaction2, key);
    await arweave.transactions.post(transaction2);
  
    var uri = "https://arweave.net/" + transaction2.id;

    return uri;

  }

  let navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  function getNetworkName(chainId)
  {

    var name = 'other network';

    switch(chainId)
    {
      case "1":
        name = 'Ethereum';
        break;

      case "56":
        name =  'BSC';
        break;

      case "137":
        name =  'Polygon';
        break;

      case "80001":
        name =  'Mumbai';
        break;

      default:
        name = 'other network';
    }

    return name;

  }


  async function mint(){

    if(window.ethereum.networkVersion !== process.env.REACT_APP_CHAIN_ID)
    {
      alert('To continue please switch to ' + getNetworkName(process.env.REACT_APP_CHAIN_ID) + ' network');

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(process.env.REACT_APP_CHAIN_ID) }],
        });
      
      return;
    }
    
    setLoading(true);
 
    await uploadToArweave()
    .then(async function(uri) {
 
      var injectedWeb3 = new Web3(window.connection);
      var contractInjected = new injectedWeb3.eth.Contract(ERC721PayableMintAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);
      
      var mintTo = window.account;
 
      var result = await contractInjected.methods.safeMint(uri)
      .send({
        from: mintTo,
        value: mintPrice,
        gasPrice: gasPrice,
        gas: 3000000

      })
      .on('transactionHash', function(hash){
        // prior to confirm
      })
      .on('confirmation', function(confirmationNumber, receipt){
        setLoading(false);
        goHome();
      })
      .on('error', function(error, receipt) {
        setLoading(false);
        var message = JSON.stringify(error);
        if(message.toLowerCase().includes('user denied'))
        {
          alert('Mint canceled.');  
        }
        else{
          alert('Mint error: ' + message);  
        }
      });

    })
    .catch(function(error){
      setLoading(false);
   });
    
  }
  
  async function getMaxSupply(){

    await contract.methods.maxSupply().call()
    .then(function(value){
      setMaxSupply(value);
    })
    .catch(function(error){
        alert('MaxSupply: ' + error);  
    });

  }

  async function getMintPrice(){

    await contract.methods.mintPrice().call()
    .then(function(value){
      setMintPrice(value);
    })
    .catch(function(error){
    });

  }

  async function getMinted(){

    await contract.methods.totalSupply().call()
    .then(function(value){
      setMinted(value);
    })
    .catch(function(error){
        alert('balanceOf: ' + error);  
    });

  }

  const [arcadeboyb64, setArcadeboyb64] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [maxSupply, setMaxSupply] = useState(0);
  const [minted, setMinted] = useState(0);
  const [mintPrice, setMintPrice] = useState(0);
  let [loading, setLoading] = useState(false);

  const [tokenPrice, setTokenPrice] = useState(0);
  const [tokenPriceUsd, setTokenPriceUsd] = useState('0');
 
  const [gasPrice, setGasPrice] = useState(0);

  let [color, setColor] = useState("#eb1414");

  function getTokenPrice() 
  {
    return fetch('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd')
    .then( resp => resp.json())
    .then((data)=> {
      var price = data['matic-network'].usd;
      setTokenPrice(price);
    })
    .catch(function(error){
   });
  }

  async function getGasPrice()
  {

    await web3.eth.getGasPrice()
    .then((data)=> {
      setGasPrice(data);
    })
    .catch(function(error){
    });

  }

  useEffect(() => {
    getGasPrice();
    getMintPrice();
    getTokenPrice();
    createBoy();
    getMaxSupply();
    getMinted();
  }, [])

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#000000',
      opacity: '90%',
      width: '200px',
      height: '100px'
  },
    overlay: {
      background: "#000000",
      opacity: '90%'
    }
  };

  const spinnerContainer: CSSProperties = {
    display: "block",
    margin: "0 auto",
    textAlign: 'center'
  };

  function getPriceUsd(ether){
    var usd = ether * tokenPrice;
    return Math.round(usd * 100) / 100;
  }

  return (
    <div className='create section__padding'>
      <div className="create-container">
        <div className="upload-img-show">
        <h3>Mint Arcade Boy</h3>
            <img id="image" width="220" height="220" src={Image} alt="banner" />
        </div>
        {loading ? (<>
          <div style={spinnerContainer}>
            <PulseLoader color={color} loading={loading} size={15} margin={8} />
            <p>Please wait patiently while minting...</p>
          </div>
        </>) : (<>
          <small><small>
            {mintPrice == 0 ? (<>
              Mint for free (Just pay gas)
              </>) : (<>
                Mint price {web3.utils.fromWei(mintPrice, 'ether')} MATIC ($ {getPriceUsd(web3.utils.fromWei(mintPrice, 'ether'))})
            </>)}
            </small></small>
          <button className='button' onClick={mint}>Mint</button>
        </>)}
      </div>

    </div>
   
  )
};

export default Create;
