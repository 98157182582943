import React, { useState } from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

import Web3 from 'web3';
import Web3Modal from "web3modal";

import logo from '../../assets/logo.png'
import { Link } from "react-router-dom";

import logoBSC from '../../assets/logos/binancechainwallet.svg';

import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnectProvider from '@walletconnect/web3-provider'

import { useEffect } from "react"
import { useNavigate } from 'react-router-dom';

import { useLocation } from "react-router-dom";

let web3Modal;
let connection;
let selectedAccount;
let humanFriendlyBalance;

const providerOptions = {

  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Dontri NFT Marketplace", // Required
      infuraId: process.env.INFURA_KEY, // Required
      rpc: "", // Optional if `infuraId` is provided; otherwise it's required
      chainId: process.env.CHAIN_ID, // Optional. It defaults to 1 if not provided
      darkMode: false // Optional. Use dark theme, defaults to false
    }
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.INFURA_KEY,
    }
  },

};

const onClickWhat = () => {
  window.open('https://www.linkedin.com/pulse/we-back-lot-fun-ireal/', "_blank");
}

const onClickHow = () => {
  window.open('https://www.linkedin.com/pulse/create-publish-arvr-product-nft-ireal/', "_blank");
}

function Menu() {

  return (
    <>
      <Link to="/"><p>Explore</p></Link>
      <p onClick={onClickWhat}>What?</p>
      <p onClick={onClickHow}>How?</p>
      {/* <Link to="/pricing"><p>Pricing</p></Link> */}
    </>
  );
}

function MyItems() {

  return (
    <>
      <Link id="toProfile" to={'/profile/' + window.account}>
        <p>My NFTs</p>
      </Link>
    </>
  );
}

function About() {

  return (

    <>
      {/* 
      <Link id="toAbout" to={'/about'}>
        <p>About</p>
      </Link>
  */}
    </>

  );
}


web3Modal = new Web3Modal({
  cacheProvider: false, // optional
  providerOptions, // required
  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});


const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);
  const [user, setUser] = useState(false);
  const { pathname } = useLocation();
  const [testnet, setTestnet] = useState(false);


  let navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  const handleLogout = () => {
    setUser(false);
    goHome();
  }

  const handleLogin = () => {
    setUser(true);
  }

  const onConnect = async () => {

    try {
      connection = await web3Modal.connect();
      localStorage.setItem('isWalletConnected', true)
      checkNetwork();
    } catch (e) {
      //  alert("Could not get a wallet connection", e);
      return;
    }

    //connection = await web3Modal.connect();

    connection.on("accountsChanged", (accounts) => {
      fetchAccountData();
    });

    connection.on("chainChanged", (chainId) => {
      fetchAccountData();
    });

    async function checkNetwork() {
      if (window.ethereum.networkVersion !== process.env.REACT_APP_CHAIN_ID) {
        alert('You are connected to ' + getNetworkName(window.ethereum.networkVersion) + ',  please switch to ' + getNetworkName(process.env.REACT_APP_CHAIN_ID) + ' network');

        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(process.env.REACT_APP_CHAIN_ID) }],
        });
      }
    }

    function getNetworkName(chainId) {

      var name = 'other network';

      switch (chainId) {
        case "1":
          name = 'Ethereum';
          break;

        case "56":
          name = 'BSC';
          break;

        case "137":
          name = 'Polygon';
          break;

        case "80001":
          name = 'Mumbai';
          break;

        default:
          name = 'other network';
      }

      return name;

    }


    connection.on("connect", (info) => {
      fetchAccountData();
    });

    connection.on("networkChanged", (networkId) => {
      fetchAccountData();
    });

    await refreshAccountData();

    handleLogin();
  }

  async function onDisconnect() {

    console.log("Killing the wallet connection", connection);

    // TODO: Which providers have close method?
    if (connection.close) {
      await connection.close();
      await web3Modal.clearCachedProvider();
      connection = null;
      localStorage.setItem('isWalletConnected', false)
    }

    handleLogout();
  }

  async function refreshAccountData() {
    await fetchAccountData(connection);
  }

  async function fetchAccountData() {

    // Get a Web3 instance for the wallet
    const web3 = new Web3(connection);
    window.web3dontri = web3;
    console.log("Web3 instance is", web3);

    // Get connected chain id from Ethereum node
    const chainId = await web3.eth.getChainId();

    // Load chain information over an HTTP API
    //const chainData = evmChains.getChain(chainId);
    //document.querySelector("#network-name").textContent = chainData.name;

    // Get list of accounts of the connected wallet
    const accounts = await web3.eth.getAccounts();

    // MetaMask does not give you all accounts, only the selected account
    console.log("Got accounts", accounts);

    selectedAccount = accounts[0];
    window.account = accounts[0];
    window.connection = connection;

    // Go through all accounts and get their ETH balance
    const rowResolvers = accounts.map(async (address) => {
      const balance = await web3.eth.getBalance(address);
      // ethBalance is a BigNumber instance
      // https://github.com/indutny/bn.js/
      const ethBalance = web3.utils.fromWei(balance, "ether");
      humanFriendlyBalance = parseFloat(ethBalance).toFixed(4);
      //alert(humanFriendlyBalance);
      // Fill in the templated row and put in the document
      //const clone = template.content.cloneNode(true);
      //clone.querySelector(".address").textContent = address;
      //clone.querySelector(".balance").textContent = humanFriendlyBalance;
      //accountContainer.appendChild(clone);
    });

    // Because rendering account does its own RPC commucation
    // with Ethereum node, we do not want to display any results
    // until data for all accounts is loaded
    await Promise.all(rowResolvers);

    // Display fully loaded UI for wallet data
    //document.querySelector("#prepare").style.display = "none";
    //document.querySelector("#connected").style.display = "block";
    //alert(humanFriendlyBalance);
    //setUser(false);
    //setUser(true);

    goHome();

  }

  useEffect(() => {

    if (process.env.REACT_APP_CHAIN_ID == 80001) {
      setTestnet(true);
    }

    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        try {
          onConnect();
          localStorage.setItem('isWalletConnected', true)
        } catch (ex) {
          console.log(ex)
        }
      }
    }
    connectWalletOnPageLoad()
  }, [])

  if (pathname === "/Product") return null;

  return (

    <div className='navbar'>
      <div className="navbar-links">
        <div className="navbar-links_logo">

          <Link id="toHome" to="/">
            <img src={logo} alt="logo" />
          </Link>

          {testnet ? (
            <>
              <div style={{ "color": "red" }} className='navbar-text'>
                iReal Testnet
              </div>
            </>) : (<></>)}

        </div>
        <div className="navbar-links_container">
          {/* 
          <input type="text" placeholder='Search Item Here' autoFocus={true} />
          */}
          <Menu />
          {user ? (
            <>
              <MyItems />
            </>) : (<></>)}
          <About />
        </div>
      </div>
      <div className="navbar-sign">
        {user ? (
          <>
            <div className="navbar-column">
              <div className='navbar-text'>
                {selectedAccount.substring(0, 6) + "..." + selectedAccount.substr(selectedAccount.length - 5, 4)}
                &nbsp;&nbsp;
              </div>
              <div className='navbar-text'>
                <small>
                  {humanFriendlyBalance + ' ' + process.env.REACT_APP_SYMBOL}
                </small>
              </div>
            </div>
            {/* <Link to="/builder">
              <button type='button' className='primary-btn' >Create</button>
            </Link> */}
            <button type='button' onClick={onDisconnect} className='secondary-btn'>Disconnect</button>
          </>
        ) : (
          <>
            {/* <Link to="/builder">
              <button type='button' className='primary-btn'>Create</button>
              &nbsp;&nbsp;&nbsp;
            </Link> */}
            <button type='button' onClick={onConnect} className='primary-btn'>Connect</button>
          </>
        )}

      </div>
      <div className="navbar-menu">
        {toggleMenu ?
          <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center" >
            <div className="navbar-menu_container-links">
              <Menu />
              {user ? (
                <>
                  <MyItems />
                </>) : (<></>)}
              <About />
            </div>
            <div className="navbar-menu_container-links-sign">
              {user ? (
                <>
                  <Link to="/builder">
                    <button type='button' className='primary-btn' >Create</button>
                  </Link>

                  <button type='button' onClick={onDisconnect} className='secondary-btn'>Disconnect</button>
                </>
              ) : (
                <>
                  <Link to="/builder">
                    <button type='button' className='primary-btn' >Create</button>
                  </Link>

                  <button type='button' onClick={onConnect} className='primary-btn'>Connect</button>
                </>
              )}

            </div>
          </div>
        )}
      </div>
    </div>

  )
}

export default Navbar
