import React from 'react'
import './downloads.css'

import IconAndroid from '../../assets/Icon_Android.png'
import IconIOS from '../../assets/Icon_IOS.png'
import IconWin from '../../assets/Icon_Win.png'
import IconMac from '../../assets/Icon_Mac.png'

import { Link } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';

import { CSSProperties } from "react";

import PulseLoader from "react-spinners/PulseLoader";

const Downloads = () => {

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#eb1414");


  
  const handleWindowsClick = () => {
    downloadAs("https://a46u57oamu4wzq2gzi4vakp4ps5g4lrij555klirtyot4bhpsyja.arweave.net/Bz1O_cBlOWzDRso5UCn8fLpuLihPe9UtEZ4dPgTvlhI", "iReal_Remote.exe")
  }

  const handleOSXClick = () => {
    downloadAs("https://etrslw2uvuxeii6l4xnaq7mdrtli7624kkpeahayxq7ghuxwd2iq.arweave.net/JOMl21StLkQjy-XaCH2DjNaP-1xSnkAcGLw-Y9L2HpE", "iReal.app.zip")
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
  }, [])


  const spinnerContainer: CSSProperties = {
    display: "block",
    margin: "0 auto",
    textAlign: 'center'
  };

  const downloadAs = async (url, filename) => {
   
    setLoading(true);

    fetch(url, {
        method: 'GET',
    }).then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
        
            setLoading(false);

        });

    }


  return (
    <div className='bids section__padding'>
      <div className="bids-container">
        <div className="bids-container-text">
          {loading ? (<>
            <h1>Downloading...</h1>
          <div style={spinnerContainer}>
                <PulseLoader color={color} loading={loading} size={15} margin={8} />
          </div>
          </>) : (<>
            <h1>Downloads</h1>
          </>)}
        </div>

        {!loading ? (<>
        <div className="bids-container-card" style={{"display": "block", "alignItems": "center", "justifyContent": "center"}}>

              <div className="card-column">
                <div className="bids-card">
                  <div className="bids-card-top">
                    <a href="https://play.google.com/store/apps/details?id=info.iReal" target="_blank">
                      <img src={IconAndroid} title="Download for Android" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=info.iReal" target="_blank">
                      <p className="bids-title">Android</p>
                    </a>
                  </div>
                  <div className="bids-card-bottom" style={{"marginTop": "10px"}}>
                  <p>Android version 7.0 and up. Also available on Huawei App Gallery.</p>
              
                  </div>
                </div>
              </div>

              <div className="card-column">
                <div className="bids-card">
                  <div className="bids-card-top">
                    {/*<a href="" target="_blank">*/}
                      <img title="Download for iOS" src={IconIOS} alt="" />
                    {/*</a>*/}
                    {/*<a href="" target="_blank">*/}
                      <p className="bids-title">iOS</p>
                    {/*</a>*/}
                  </div>
                  <div className="bids-card-bottom">
                  <p style={{"marginTop": "10px"}}>Soon.</p>
                  </div>
                </div>
              </div>


        <div className="card-column">
          <div className="bids-card">
            <div className="bids-card-top">          
              <img style={{"cursor": "pointer"}} onClick={handleWindowsClick} src={IconWin} title="Download for Windows" />         
              <p style={{"cursor": "pointer"}} onClick={handleWindowsClick} title="Download for Windows" className="bids-title">Windows 7/8/10</p> 
            </div>
            <div className="bids-card-bottom" style={{"marginTop": "10px"}}>
            <p>Remote app only for Windows.</p>
            </div>
          </div>
        </div>

        <div className="card-column">
          <div className="bids-card">
            <div className="bids-card-top">
              <img style={{"cursor": "pointer"}} onClick={handleOSXClick} src={IconMac} title="Download for Mac OSX" />
              <p style={{"cursor": "pointer"}} onClick={handleOSXClick} title="Download for Mac OSX" className="bids-title">Mac OSX</p>
            </div>
            <div className="bids-card-bottom" style={{"marginTop": "10px"}}>
            <p>Remote app only for Mac OSX.</p>

            </div>
          </div>
        </div>

        </div>
        </>) : (<>
          </>)}
      </div>

    </div>
  )

};

export default Downloads;
