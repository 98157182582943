import React from 'react'
import './pricing.css'

import Image from '../../assets/Image.png'
import iRealLogo from '../../assets/profile.jpg'
import imgPricing from '../../assets/Magazine.png'

import { useState } from 'react';
import { useEffect } from 'react';

import mergeImages from 'merge-images';

import Web3 from 'web3';
import iRealNFTPolyAbi from '../../abis/iRealNFTPolyAbi.json'

import { CSSProperties } from "react";

import PulseLoader from "react-spinners/PulseLoader";

import { useNavigate } from "react-router-dom";

import Arweave from "arweave";

import { AiOutlineInstagram,AiOutlineTwitter, } from "react-icons/ai";

var provider = process.env.REACT_APP_PROVIDER;
var web3Provider = new Web3.providers.HttpProvider(provider);
var web3 = new Web3(web3Provider);

const contract = new web3.eth.Contract(iRealNFTPolyAbi, process.env.REACT_APP_NFT_CONTRACT_MATIC);


const arweave = Arweave.init({
  host: "arweave.net",
  port: 443,
  protocol: "https",
  timeout: 20000, // Network request timeouts in milliseconds
  logging: false, // Disable network request logging
});

const Pricing = () => {

  let navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  
  function getNetworkName(chainId)
  {

    var name = 'other network';

    switch(chainId)
    {
      case "1":
        name = 'Ethereum';
        break;

      case "56":
        name =  'BSC';
        break;

      case "137":
        name =  'Polygon';
        break;

      case "80001":
        name =  'Mumbai';
        break;

      default:
        name = 'other network';
    }

    return name;

  }


  const [tokyoGrls, setTokyoGrls] = useState('');
  const [minted, setMinted] = useState(0);
  let [loading, setLoading] = useState(false);

  const [tokenPrice, setTokenPrice] = useState(0);
  const [tokenPriceUsd, setTokenPriceUsd] = useState('0');
  const [mintPrice, setMintPrice] = useState(0);

  const [posterIndex, setPosterIndex] = useState(0);

  const [gasPrice, setGasPrice] = useState(0);

  let [color, setColor] = useState("#eb1414");

  const onClickInstagram = () => {
    window.open('https://www.instagram.com/irealxr', "_blank");
  }

  const onClickTwitter = () => {
    window.open('https://twitter.com/irealxr', "_blank");
  }

  const onClickBuilder= () => {
    window.open('ireal://builder');
  }

  const onClickDownload= () => {
    window.open('https://play.google.com/store/apps/details?id=info.iReal');
  }

  useEffect(() => {

  }, [])

  return (
    <div className='create section__padding'>
      <div className="create-container">
      <div className="upload-img-show">
            <img id="image" src={imgPricing} />
        </div>     
        <div className="header-slider" style={{"marginTop": "0rem", "width": "100%"}}>
					<h4 style={{"display": "flex", "justifyContent": "center", "color": "#000000", "width": "100%", "marginTop": "1rem"}}>
            iReal Builder: One-time payment
            </h4>
            <h3 style={{"fontSize": "16px","display": "flex", "justifyContent": "center", "color": "#000000", "width": "100%", "marginTop": "1rem"}}>
            There are no subscription services and no ongoing service fees beyond the initial cost.
            </h3>
					<h3 style={{"color": "#000000", "width": "100%", "marginTop": "1rem"}}>
          
          <br /><br />
          <b>Plane Tracking:</b> 10 MATIC + Storage cost.
          <br /><br />
          <b>Immersive VR:</b> 10 MATIC + Storage cost.
          <br /><br />
          <b>Image Tracking:</b> 10 MATIC (every 3 trackers) + Storage cost.
          <br /><br />
          <br /><br />
          <b>Stored in Arweave:</b> All XR product files, and media files are stored in Arweave for at least the next 200 years.
          Arweave is a Decentralized Storage Network (DSN) designed to provide scalable, cost-effective, and permanent data storage, Arweave is built on the blockchain.
          <br /><br />

          <b>How much does it cost to store on Arweave?</b> The price to store data on Arweave is dynamic but tends to fluctuate within a narrow range of <b>$2.35 to $5.00 per GB.</b> In reality, for most uploaded files, this price difference will only be a fraction of a cent. 
          <a href='https://ardrive.io/pricing/' target='_blank' style={{"color": "#ff0000"}}> Arweave price calculator</a>

          <br /><br />

            <div style={{"display": "flex", "justifyContent": "center", "margin": "0 auto", "width": "100%", "marginTop": "1rem"}}>
            <button type='button' onClick={onClickDownload} className='primary-btn'>Download</button>
            &nbsp;&nbsp;
              <button type='button' onClick={onClickBuilder} className='primary-btn'>Build now</button>
            </div>

          </h3>
				</div>

      </div>

    </div>
   
  )
};

export default Pricing;
