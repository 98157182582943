import React from 'react';
import './Product.css'
import {Link} from 'react-router-dom'
import ReactDOM from 'react-dom';

import 'aframe';
import 'aframe-particle-system-component';
import {Entity, Scene} from 'aframe-react';

const Product = () => {

  return (
 
    <Scene vr-mode-ui="enterVRButton: #myEnterVRButton; enterARButton: #myEnterARButton">
      <Entity geometry={{primitive: 'box'}} material={{color: 'red'}} position={{x: 0, y: 0, z: -5}}/>
      <Entity light={{type: 'point'}}/>
      <Entity text={{value: 'Hello, WebVR!'}}/>
      <a id="myEnterVRButton" className="vr" href="#">aaaaa</a>
      <a id="myEnterARButton" className="ar" href="#">bbbbb</a>
    </Scene>
  
   )
};

export default Product;
