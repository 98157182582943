import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SEO from "react-seo-component"

{/*
<SEO
  title='iReal'
  titleTemplate='Marketplace'
  titleSeparator='-'
  description='iReal.'
  image=''
  pathname='http://ireal.ar'
  siteLanguage='en'
  siteLocale='en_us'
  twitterUsername='@ImDontri'
  author='iReal'
/>
   */}
ReactDOM.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

