import React from 'react';
import './home.css'
import {Bids, Header, CollectiblesGallery, Market, Info} from '../../components'

const Home = () => {
    
return <div>

    <Market />
    <Info />

</div>;

};

export default Home;

