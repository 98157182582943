import React from 'react'
import './App.css';
import {Navbar,Footer} from './components'
import {Home,Profile,Item, Login,Register, ArcadeBoysCreate, Poly, BuilderCreate, Product, Downloads, About, Pricing} from './pages'
import { Routes, Route, BrowserRouter } from "react-router-dom";

function App() {
 

  return (
    <div>
      <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/item/:id" element={<Item />} />
            <Route path="/poly/:id" element={<Poly />} />
            <Route path="/downloads" element={<Downloads /> } />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/login" element={ <Login />} />
            <Route path="/register" element={ <Register />} />
            <Route path="/arcadeboys" element={<ArcadeBoysCreate /> } />
            <Route path="/builder" element={<BuilderCreate /> } />
            <Route path="/pricing" element={<Pricing /> } />
            <Route path="/product" element={ <Product />} />
            <Route path="/about" element={<About /> } />
          </Routes>
      <Footer />
    </div>
  );
}

export default App;
