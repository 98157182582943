import React from 'react'
import './footer.css'
import nftlogo from '../../assets/logo.png'
import { AiOutlineInstagram,AiOutlineTwitter, } from "react-icons/ai";
import { RiDiscordFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import {  Link } from "react-router-dom";

const Footer = () => {

  const { pathname } = useLocation();

  if (pathname === "/Product") return null;

  
  const onClickInstagram = () => {
    window.open('https://www.instagram.com/irealxr', "_blank");
  }

  const onClickTwitter = () => {
    window.open('https://twitter.com/irealxr', "_blank");
  }

  const onClickGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=info.iReal', "_blank");
  }

  const onClickYoutube = () => {
    window.open('https://www.youtube.com/channel/UCp4pdQiE9AqOR2LYvdj5G9Q', "_blank");
  }

  const onClickOpensea = () => {
    window.open('https://opensea.io/irealxr', "_blank");
  }

  const onClickRarible = () => {
    window.open('https://rarible.com/irealxr', "_blank");
  }

  return (
    <div className='footer section__padding'>
      <div className="footer-links">

        <div className="footer-links_div">
          <h4>iReal</h4>
          <p onClick={onClickTwitter}>Twitter</p>
          <Link id="toDownloads" to={'/downloads'}>
            <p>Downloads</p>
          </Link>
        </div>
        <div className="footer-links_div">
          <h4>Marketplace</h4>
          <p onClick={onClickOpensea}>Opensea</p>
          <p onClick={onClickRarible}>Rarible</p>
        </div>
      </div>
      
      <div className="footer-copyright">
        <div>
        <p> © {(new Date().getFullYear())} iReal. All Rights Reserved</p>
        </div>
        <div>
          <AiOutlineInstagram onClick={onClickInstagram} size={25} color='white' className='footer-icon' />
          <AiOutlineTwitter onClick={onClickTwitter} size={25} color='white' className='footer-icon'/>
        </div>

      </div>
    </div>
  )
}

export default Footer
